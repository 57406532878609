var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{staticStyle:{"padding":"0px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{staticStyle:{"background-color":"#fff","line-height":"60px","margin":"0","width":"100%","height":"60px"}},[_c('div',{staticClass:"Enter-top"},[_c('div',[_c('div',[_c('a-breadcrumb',{staticStyle:{"margin":"18px 0"}},[_c('a-breadcrumb-item',[_vm._v("版本")]),_c('a-breadcrumb-item',[_vm._v("我的套餐")])],1)],1)])])]),_c('a-layout',{staticStyle:{"padding":"24px","height":"100%","overflow":"auto"}},[_c('a-layout-content',{style:({
        background: '#fff',
        'min-width': '1220px',
        padding: '24px',
        margin: 0,
        overflow: _vm.auto,
      })},[_c('div',{staticClass:"search-wrap"},[_c('a-tabs',{attrs:{"default-active-key":"1"},on:{"change":_vm.callback}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"基本信息"}},[_c('a-descriptions',{attrs:{"title":"套餐信息"}}),_vm._l((_vm.packages),function(item){return _c('a-descriptions',{key:item.id,staticStyle:{"padding-left":"20px"},attrs:{"column":3,"title":"","layout":"vertical"}},[_c('a-descriptions-item',{attrs:{"label":"套餐名称"}},[_vm._v(" "+_vm._s(item.packageNameSnapshot)+" ")]),_c('a-descriptions-item',{attrs:{"label":"套餐开始日期"}},[_vm._v(" "+_vm._s(item.startTime)+" ")]),_c('a-descriptions-item',{attrs:{"label":"套餐结束日期"}},[_vm._v(" "+_vm._s(item.endTime)+" ")]),_c('a-descriptions-item',{attrs:{"label":"套餐状态"}},[_vm._v(" "+_vm._s(item.state == 0 ? '正常' : '异常')+" ")])],1)}),_c('a-descriptions',{attrs:{"title":"套餐内容"}}),_c('a-descriptions',{staticStyle:{"padding-left":"20px"},attrs:{"title":"","layout":"vertical"}},_vm._l((_vm.details),function(item){return _c('a-descriptions-item',{key:item.id,attrs:{"label":item.name}},[_vm._v("  "+_vm._s(item.type == 5 ? '无限制' : item.path + '/' + item.value)+" ")])}),1)],2),_c('a-tab-pane',{key:"2",attrs:{"tab":"套餐信息","force-render":""}},[_c('div',{staticClass:"flow-d-row-bt",staticStyle:{"padding":"0 200px"}},_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"list-div flow-d-col",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"flow-d-center",staticStyle:{"height":"50px","font-size":"17px","border-bottom":"1px solid #d9d9d9","font-weight":"600"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"flow-d-center",staticStyle:{"height":"80px","font-size":"25px","font-weight":"600","color":"#5383c1"}},[_vm._v(" ￥"+_vm._s(item.price)+" ")]),_vm._l((item.details),function(it,index){return _c('div',{key:it.id,staticClass:"div-center",style:(index % 2 === 1
                      ? 'background-color:#fff'
                      : 'background-color:#f8f8f8')},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(it.name)+":")]),_c('span',[_vm._v(_vm._s(it.value))])])}),_c('div',{staticStyle:{"flex":"1"}},[_c('a-button',{staticStyle:{"width":"200px","position":"absolute","bottom":"10px","left":"50px","right":"50px"},attrs:{"type":"primary"}},[_vm._v("立即升级")])],1)],2)}),0)])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }